









































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  setup(_, { root }) {
    const state = reactive({
      loading: false,
      success: false,
      error: false as boolean | number,
      loaded: false,
      plans: [],
    });

    const model = reactive({
      plan: [] as any,
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc("layout.errors.companyExists")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`company/${root.$route.params.cid}`)
        .then(({ data: { company } }) => {
          state.plans = company.plans.reverse();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      let plan = "";

      const formData = new FormData();

      formData.append("file", model.plan);

      if (model.plan) {
        await axiosInstance
          .post(`file/company/panel/${root.$route.params.id}`, formData, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(({ data: { id } }) => {
            plan = id;
          })
          .catch((e) => {
            console.log(e);
          });
      }

      const data = {
        exhibitionPlan: plan,
      };

      axiosInstance
        .post(`company/${root.$route.params.cid}/exhibition-plan`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc("panel.event.company.message.success"),
          });

          model.plan = [];
          fetchData();
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        });
    };

    const getFile = (name: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`file/${name}`, { responseType: "blob" })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `${name}`);
          document.body.appendChild(fileLink);

          fileLink.click();
          fileLink.remove();
        })
        .catch((e) => console.log(e));
    };

    const deleteFile = (item: { id: string; removeDialog: boolean }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`company/document/${item.id}`, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          item.removeDialog = false;
          fetchData();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    return { state, model, onSubmit, getFile, deleteFile };
  },
});
